#m_container_training {
    position: relative;
    place-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 100%;
}

#m_image-container {
    z-index: 1;
    max-width: 100%;
    display: block;
    justify-content: center;
    width: 100%;
}

#m_image-container img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    justify-content: center;
}

#m_training {
    display: block;
    justify-content: center;
    width: 100%;
    height: auto;
}


.m_training_button>a {
    text-decoration: none;
    color: #000000;
}

.m_training_button:hover {
    color: #ffffff;
    background: #004484;
}


.m_training-item {
    margin: 2vw;
    position: relative;
    justify-content: space-between;
    z-index: 2;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    border: 0.5vw solid #FF9F00;
    border-radius: 4vw;
    box-sizing: border-box;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
}

.m_training-icon {
    position: relative;
    top: -2vw;
    width: 17vw;
    height: 17vw;
}

.m_training_text {
    font-family: 'Arial';
    font-size: 4vw;
}

.m_training-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3vw;
    z-index: 2;
    position: absolute;
  }