#container_training {
    position: relative;
    place-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
}

#image-container {
    z-index: 1;
    max-width: 100%;
    display: block;
    justify-content: center;
    width: 100%;
}

#image-container img {
    z-index: 1;
    max-width: 100%;
    display: block;
    justify-content: center;
    width: 100%;
}

#training {
    display: block;
    justify-content: center;
    width: 100%;
    height: auto;
}

.button-container {
    position: absolute;
    bottom: 7vw;
    right: 5vw;
}

.training_button {
    cursor: pointer;
    margin-right: 5vw;
    font-family: 'Arial';
    font-weight: bold;
    font-size: 4vw;
    color: #000000;
    padding: 2vw 4vw;
    border-radius: 2vw;
    border: 2px;
    background: #FF9F00;
}

.training_button>a {
    text-decoration: none;
    color: #000000;
}

.training_button:hover {
    color: #ffffff;
    background: #004484;
}


.training-row {
    position: absolute;
    justify-content: space-between;
    top: 0;
    z-index: 2;
    display: flex;
    bottom: 44vw;
    margin: 4vw;
}

.training-item {
    position: relative;
    justify-content: space-between;
    z-index: 2;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 20vw;
    padding-top: 5vw;
    margin: 4vw;
    border: 0.5vw solid #FF9F00;
    border-radius: 4vw;
    box-sizing: border-box;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
}

.training-icon {
    position: relative;
    top: -2vw;
    width: 6vw;
    height: 6vw;
}

.training_text {
    width: 10vw;
    font-family: 'Arial';
    font-size: 1.5vw;
}