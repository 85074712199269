.container {
    position: relative;
    place-items: center;
    width: 100%;
    overflow-y: auto;
}

.container img {
    display: block;
    justify-content: center;
    width: 100%;
}

.video-container {
    position: absolute;
    bottom: 30%;
    right: 1vw;
    border: 5vw #59b7e3;
    /* Толщина и цвет рамки */
    border-radius: 5vw;
    padding-right: 1vw;
    padding-bottom: 1.2vw;
    width: 25vw;
    height: 40vw;
    object-fit: cover;
}

.title {
    margin-left: -1vw;
    margin-top: -4.5vw;
    position: absolute;
    font-family: 'Arial';
    font-weight: 700;
    font-size: 4vw;
    color: #ffffff;
    top: 5vw;
    left: 5vw;
    border-radius: 5px;
    z-index: 1;
    /* Чтобы title был поверх других элементов */
}
.my_info li::before {
    content: "✔"; /* Ваш выбранный символ */
    margin-right: 2vw;
}
.my_info {
    width: 62vw;
    list-style-type: none;
    margin-left: 1vw;
    margin-top: 7vw;
    position: absolute;
    font-family: 'Arial';
    font-weight: 400;
    font-size: 1.7vw;
    font-weight: bold;
    line-height: 1.1;
    color: #000000;
    top: 5vw;
    left: 5vw;
    border-radius: 5px;
    z-index: 1;
}

.my_info li {
    margin-bottom: 2vw;
    margin-left: 0;
}

.my_info_more {
    width: 50vw;
    overflow-wrap: break-word;
    margin-left: 1vw;
    margin-top: 31vw;
    position: absolute;
    font-family: 'Arial';
    font-weight: 400;
    font-size: 1.7vw;
    color: #ffffff;
    top: 5vw;
    left: 5vw;
    border-radius: 5px;
    z-index: 1;
    /* Чтобы title был поверх других элементов */
}