
.m_active {
  border: 2px solid #FF9F00;
  transform: scale(1.3);
  transition: all 300ms;
  overflow-y: auto;
}

.m_slideshow {
  display: flex;
  background: none;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
}

.m_mainImg {
  transition: all 500ms;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: auto;
}

.m_actions {
  top: -30vw;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.m_actions button {
  background-color: rgba(255, 159, 0, 0.8);
  border: none;
  cursor: pointer;
  padding: 1vw;
  margin: 1vw;
  border-radius: 1.5vw;
}

.m_actions button:active {
  background: #000000a2;
  border: none;
  cursor: pointer;
  padding: 1vw;
  margin: 1vw;
  border-radius: 2vw;
}

.m_tumbnail {
  display:flex;
  position: relative;
  top: -5vw;
  justify-content: space-around;
  align-items: center;
  gap: 15vw;
  
}

.m_thumbnail-img {
  width: 8vw;
  height: 8vw;
  border: 0.5vw solid transparent; /* прозрачная граница */
  cursor: pointer;
  border-radius: 2vw;
  transition: border-color 0.3s ease; /* плавное изменение цвета границы */
}

.m_thumbnail-img.active {
  border-color: #FF9F00;
  padding: 1vw; /* цвет границы для активного изображения */
}

.m_thumbnail-img:hover {
  border-color: #FF9F00;
  border-radius: 2vw;
  padding: 1vw; /* цвет границы при наведении */
}

.m_image-text {
  display:flex;
  line-height: 1.2;
  margin-bottom: 20vw;
  margin-left: 7vw;
  margin-right: 7vw;
  position: absolute;
  font-family: 'Arial';
  font-size: 3vw;
  text-align: left;
  color: #ffffff;
}