
.active {
  border: 2px solid #1db9b9;
  transform: scale(1.3);
  transition: all 300ms;
  overflow-y: auto;
}

.slideshow {
  display: flex;
  background: none;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
}

.mainImg {
  transition: all 500ms;
  border-radius: 5vw; 
  justify-content: center;
  align-content: center;
  width: 70vw;
  height: auto;
}

.actions {
  position: relative;
  top: -21vw;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 70vw;
}

.actions button {
  background-color: rgba(255, 159, 0, 0.8);
  border: none;
  cursor: pointer;
  padding: 1vw;
  margin: 1vw;
  border-radius: 1.5vw;
}

.actions button:active {
  background: #000000a2;
  border: none;
  cursor: pointer;
  padding: 1vw;
  margin: 1vw;
  border-radius: 2vw;
}

.tumbnail {
  display:flex;
  position: relative;
  top: -4vw;
  justify-content: space-around;
  align-items: center;
  min-width: 80vw;
  gap: 1vw;
  
}

.thumbnail-img {
  width: 4vw;
  height: 4vw;
  border: 0.5vw solid transparent; /* прозрачная граница */
  cursor: pointer;
  border-radius: 2vw;
  transition: border-color 0.3s ease; /* плавное изменение цвета границы */
}

.thumbnail-img.active {
  border-color: #FF9F00;
  padding: 0.5vw; /* цвет границы для активного изображения */
}

.thumbnail-img:hover {
  border-color: #FF9F00;
  border-radius: 2vw;
  padding: 0.5vw; /* цвет границы при наведении */
}

.image-text {
  width: 60vw;
  display:flex;
  line-height: 1.5;
  margin-bottom: 15vw;
  position: absolute;
  font-family: 'Arial';
  font-weight: bold;
  font-size: 1.5vw;
  color: #ffffff;
}