.contact_container {
  display: flex;
  justify-content: space-around;
  overflow-y: auto;
}

#contacts_en {
  border-color: #FF9F00;
  border: 2vw;
}

label {
  display: block; 
  font-family: 'Arial';
  font-weight: bold;
/* Устанавливает блочный вид для названий */
}

.contact_me {
/* Выравниваем текст по центру */
  color: rgb(0, 0, 0);
  font-family: 'Arial';
  font-weight: bold; 
  margin-left: 7vw;
  font-size: 3vw;
  margin-bottom: 2vw;
  margin-top: 2vw; /* Устанавливаем цвет текста */
}


#Name_textarea_en, #Email_textarea_en  {
  height: 2vw;
  width: 80%;
  border: none;
  border-bottom: 0.3vw solid #000000; /* Граница снизу */
  padding-bottom: 1vw;
  margin-bottom: 1vw;
  margin-left: 7vw;
  text-align: left;
  font-family: 'Arial';
  font-weight: bold;
  font-size: 2vw;
  background-color: rgba(255, 255, 255, 0);
}

#Message_textarea_en {
  height: 8vw;
  width: 80%;
  border: none;
  margin-left: 7vw;
  text-align: left;
  font-family: 'Arial';
  font-weight: bold;
  font-size: 2vw; 
  background-color: rgba(255, 255, 255, 0.5);
}

#Message_textarea_en::placeholder, #Name_textarea_en::placeholder, #Email_textarea_en::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.contact_final_description_en {
  display: flex;
  flex-direction: column; 
  justify-content: space-around;
  align-items: center;
}

.icons {
  font-size: 6vw;
  margin: 1vw;
}

.contact_final_description_text1_en {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 2vw; 
  text-align: center;
  margin: 2vw;
  margin-right: 6vw;
}
#Whatsapp, #Telegram, #Instagram {
  margin: 1.5vw;
}
.button-sent-container_en {
  display: flex;
  justify-content: right; 
  width: 45vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
}
#button_send_en {
  display: flex;
  justify-content: center; 
  width: 50%;
  font-family: 'Arial';
  font-weight: bold;
  font-size: 4vw;
  color: #000000;
  padding: 1vw 1vw;
  margin-right: 1vw;
  border-radius: 2vw;
  border: 2px;
  background: #FF9F00;
}

#button_send_en:hover {
  cursor: pointer;
  color: #ffffff;
  background: #004484;
}

.center-logo-bottom img {
  border-radius: 1vw;
  padding: 1vw;
  background-color: rgba(255, 255, 255, 0.5);
  width: 10vw;
  height: auto;
}

h3.contact_me {
  text-align: left;
  width: 50vw;
  font-family: 'Arial';
  font-weight: bold;
  font-size: 3vw;
  margin-left: 3vw;
}