.navbar {
    display: flex;
    background: none;
    justify-content: space-around;
    align-items: center;
}

.center-logo img {
    width: 10vw;
    height: auto;
}


.nav-button {
    text-decoration: none;
    cursor: pointer;
    margin-right: 5vw;
    font-family: 'Arial';
    font-weight: bold;
    font-size: 2vw;
    color: #000000;
    padding: 1vw 3vw;
    border-radius: 20px;
    border: 1px;
    background: #FF9F00;


}

.nav-button:hover {
    color: #ffffff;
    background: #004484;
}

.languages {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 2vw;
}


.button_language_en>a,
.button_language_de>a,
.button_language_ru>a {
    text-decoration: none;
    color: #000000;
}

.button_language_de,
.button_language_ru,
.button_language_en {
    cursor: pointer;
    align-items: center;
    font-family: 'Arial';
    font-size: 1.5vw;
    border: 1px;
    background: rgba(209, 242, 255, 1) 100%;
}

.button_language_de:hover,
.button_language_ru:hover,
.button_language_en:hover {
    color: #ffffff;
    background: #004484;
}

.button_language_en:focus,
.button_language_de:focus,
.button_language_ru:focus {
    background-color: #FF9F00;
}
