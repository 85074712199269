.footer_text {
    padding-top: 2vw;
    padding-bottom: 1vw;
    text-align: center;
    border-top: 2vw;
    font-family: 'Arial';
    font-weight: 300;
    font-size: 5vw;
    margin-top: 3vw;
    color: rgb(0, 0, 0);
}