.grid-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    grid-template-columns: repeat(3, 1fr); 
    
}

.grid-row {
    display: flex;
    justify-content: space-between;
    margin-left: 9vw;
    margin-right: 9vw;
    margin-bottom: 3vw;
}

.grid-item {
    padding: 4vw;
    border: 0.5vw solid #FF9F00;
    border-radius: 5vw;
    box-sizing: border-box;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    
}

.icon {
    position: relative;
    top: -2vw;
    width: 6vw;
    height: 6vw;
}

.text {
    font-family: 'Arial';
    font-size: 1.5vw;
    text-align: center;
    width: 15vw;
}