.Training_page,
.Training_page_ru .home,
#root,
body {
    background: linear-gradient(0deg, rgba(5, 78, 96, 1) 0%, rgba(180, 224, 255, 1) 49%, rgba(209, 242, 255, 1) 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

h1 {
    margin-left: -1vw;
    margin-top: -2vw;
    position: absolute;
    font-family: 'Arial';
    font-weight: 700;
    font-size: 3vw;
    color: #ffffff;
    top: 5vw;
    left: 5vw;
    border-radius: 5px;
    z-index: 1;
}

h3 {

    text-align: left;
    width: 50vw;
    font-family: 'Arial';
    font-weight: bold;
    font-size: 3vw;
    margin-left: 3vw;
}

.name_level {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: 'Arial';
    font-weight: bold;
    font-size: 15px;
    /* Установите желаемый вес шрифта */
    /* Дополнительные стили по желанию */
}

.homepage_price {
    display: flex;
    font-family: 'Arial';
    font-weight: bold;
    font-size: 3vw;
    justify-content: center;
    align-items: center;
    margin-left: 36.7vw;
    width: 20vw;
    text-align: center;
    padding: 2vw;
    border: 0.5vw solid #FF9F00;
    border-radius: 4vw;
}