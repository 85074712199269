.dropdown-menu {
  display: flex;
  justify-content: space-between;
  position: fixed;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  padding-bottom: 2vw;
  padding-top: 2vw;
  background-color: rgba(0, 68, 132, 0.4); 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;

}

.dropdown-button {
  background-color: #ffffff00;
  border: 1px solid #cccccc00;
  cursor: pointer;
}

.menu-content {
  position: absolute;
  left: auto;
  top: 0;
  right: 0; /* Установка правого выравнивания */
  width: 70%;
  height: auto;
  background-color: #fff;
  border-top: none;
  display: none;
}

.menu-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-content ul li {
  cursor: pointer;
}

.menu-content ul li:hover {
  background-color: #f0f0f0;
}

.menu-content.show {
  display: block;
}

.vertical-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


.menu-button {
  display: block;
  width: 100%;
  padding-top: 4vw; 
  padding-bottom: 4vw;/* Отступы вокруг текста кнопки */
  text-decoration: none;
  font-family: 'Arial';
  font-weight: 300;
  font-size: 6vw;
  color: #000000;
  text-align: center;
  background-color: rgba(59, 112, 184, 1); /* Цвет фона кнопки */
 /* Цвет текста кнопки */
  border: none;
  cursor: pointer;
}

.menu-button:hover {
  color: #ffffff;
  background: #004484; /* Цвет фона кнопки при наведении */
}

.mobile_languages {
  display: grid;
}

.mobile_button_language_en>a,
.mobile_button_language_de>a,
.mobile_button_language_ru>a {
    text-decoration: none;
    color: #000000;
}

.mobile_button_language_de,
.mobile_button_language_ru,
.mobile_button_language_en {
    cursor: pointer;
    padding-top: 2vw;
    padding-bottom: 2vw;
    align-items: center;
    font-family: 'Arial';
    font-size: 3vw;
    border: 90px;
    background: rgba(59, 112, 184, 1);
}

.mobile_button_language_de:hover,
.mobile_button_language_ru:hover,
.mobile_button_language_en:hover {
    color: #ffffff;
    background: #FF9F00;
}

.mobile_button_language_en:focus,
.mobile_button_language_de:focus,
.mobile_button_language_ru:focus {
    background-color: #FF9F00;
}


.selected {
    background-color: #FF9F00;
}

.mobile_logo {
  width: 14vw;
  height: 14vw;
  margin-left: 2vw;
}

.mobile_title {
  font-family: 'Arial';
  font-size: 8vw;
  text-align: center;
  opacity: 0.7;
}

.personal_coach {
  font-family: 'Arial';
  font-size: 5vw;
  text-align: center;
  opacity: 0.7;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
}
